import * as PropTypes from "prop-types"
import React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/layout"

import SEO from "../components/seo"
import Particles from "../components/particle/particle"

import Pricing from "../components/pricing/index"
import Clients from "../components/companies/companies-overview"

class PricingTemplate extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      allPricingJson: PropTypes.object.isRequired,
    }),
  }
  render() {
    const pricingData = this.props.data.allPricingJson.edges[0].node

    return (
      <Layout>
        <SEO title={`Pricing plan for ${pricingData.title}`} />
        <Particles />
        <Pricing pricing={pricingData} />
        <Clients />
      </Layout>
    )
  }
}

export default PricingTemplate

export const pageQuery = graphql`
  query($path: String!) {
    allPricingJson(filter: { path: { eq: $path } }) {
      edges {
        node {
          data {
            featuredPlan
            name
            description
            price {
              monthly
              yearly
              currency
            }
            priceLabel {
              monthly
              yearly
            }
            buttonLabel
            url {
              monthly
              yearly
            }
            listItems {
              content
              type
              description
              readMoreLink
            }
          }
          path
          title
          subtitle
          paymentterms {
            name
            value
          }
        }
      }
    }
  }
`
