import React, { useEffect, useState } from "react"
import LinkExternalInternal from "../link/link"

import PricingTable, {
  PricingHead,
  PricingHeadTitle,
  PricingHeadSubTitle,
  PricingPrice,
  PricingButton,
  PricingList,
  ListItem,
} from "./pricing"
import LightTooltip from "../popup/popup-tooltip"
import PopupDialogContent from "../popup/popup-dialog-content"
import Icon from "react-icons-kit"
import { checkmark } from "react-icons-kit/icomoon/checkmark"
import Chip from "@material-ui/core/Chip"

const numberWithCommas = x => {
  x = x.toString()
  var pattern = /(-?\d+)(\d{3})/
  while (pattern.test(x)) x = x.replace(pattern, "$1,$2")
  return x
}

const PricingTableItem = props => {
  const [price, setPrice] = useState(null)

  const NUMBEROFMONTHS = 12

  useEffect(() => {
    // Zaktualizuj tytuł dokumentu korzystając z interfejsu API przeglądarki
    const priceMonthly = !props.monthly
      ? props.pricingTable.price.monthly * props.discount
      : (props.pricingTable.price.yearly / NUMBEROFMONTHS) * props.discount
    setPrice(priceMonthly)
  }, [props.monthly, props.discount])

  const checkPricingType = (type, content) => {
    switch (type) {
      case "price-user":
        return `${props.pricingTable.price.currency}${parseFloat(
          price / content
        ).toFixed(4)} / 1 User${props.monthly ? "" : ""}`
      case null:
      case "":
        return content
    }
  }

  const YEARLY_PRICE = Math.round(
    props.pricingTable.price.yearly * props.discount
  )
  const MONTHLY_PRICE = Math.round(
    props.pricingTable.price.monthly * props.discount
  )
  //const YEARLY_DISCOUNT = (YEARLY_PRICE / (MONTHLY_PRICE * 12) - 1) * -1 * 100
  //const YEARLY_DISCOUNT = (MONTHLY_PRICE * 2)
  const YEARLY_DISCOUNT = MONTHLY_PRICE * 12 - YEARLY_PRICE

  return (
    <PricingTable
      className="pricing_table"
      color={props.pricingTable.featuredPlan ? "#387ABE" : "#f2f4f7"}
    >
      <PricingHead>
        <PricingHeadTitle>{props.pricingTable.name}</PricingHeadTitle>
        <PricingHeadSubTitle>
          {props.pricingTable.description}
        </PricingHeadSubTitle>
      </PricingHead>
      <PricingPrice>
        <h2 style={{ textAlign: "center", marginBottom: 5 }}>
          {props.monthly === false ? (
            <>
              {props.pricingTable.price.currency != "" ? (
                <div>
                  {props.pricingTable.price.currency}
                  {numberWithCommas(MONTHLY_PRICE)}
                </div>
              ) : (
                <div>{numberWithCommas(props.pricingTable.price.monthly)}</div>
              )}
            </>
          ) : (
            <>
              {props.pricingTable.price.currency != "" ? (
                <div>
                  {props.pricingTable.price.currency}
                  {numberWithCommas(YEARLY_PRICE)}
                </div>
              ) : (
                <div>{numberWithCommas(props.pricingTable.price.yearly)}</div>
              )}
            </>
          )}
        </h2>
        <h5 style={{ textAlign: "center", marginTop: 5, marginBottom: 5 }}>
          {props.monthly === false
            ? props.pricingTable.priceLabel.monthly
            : props.pricingTable.priceLabel.yearly}
        </h5>
        {props.monthly && !isNaN(YEARLY_DISCOUNT) ? (
          <Chip
            size="small"
            label={
              "Save $" +
              numberWithCommas(parseFloat(YEARLY_DISCOUNT).toFixed(0))
            }
            style={{ fontSize: "0.7em" }}
          />
        ) : (
          <div style={{ minHeight: 24 }} />
        )}
      </PricingPrice>
      <LinkExternalInternal
        to={
          props.monthly === false
            ? props.pricingTable.url.monthly
            : props.pricingTable.url.yearly
        }
        state={{
          packageType: props.pricingTable.name,
        }}
      >
        <PricingButton featured={props.pricingTable.featuredPlan}>
          {props.pricingTable.buttonLabel}
        </PricingButton>
      </LinkExternalInternal>
      <PricingList>
        {props.pricingTable.listItems.map((item, index) => (
          <>
            {item.description ? (
              <LightTooltip
                title={
                  <PopupDialogContent to={item.readMoreLink}>
                    {item.description}
                  </PopupDialogContent>
                }
                placement="right"
                interactive="true"
              >
                <ListItem key={`pricing-table-list-${index}`}>
                  <Icon
                    icon={checkmark}
                    className="price_list_icon"
                    size={13}
                  />
                  <div style={{ borderBottom: "1px #3e4346 dashed" }}>
                    {checkPricingType(item.type, item.content)}
                  </div>
                </ListItem>
              </LightTooltip>
            ) : (
              <ListItem key={`pricing-table-list-${index}`}>
                <Icon icon={checkmark} className="price_list_icon" size={13} />
                {checkPricingType(item.type, item.content)}
              </ListItem>
            )}
          </>
        ))}
      </PricingList>
    </PricingTable>
  )
}

export default PricingTableItem
