import Slider from "react-slick"
import React from "react"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import useMediaQuery from "@material-ui/core/useMediaQuery"

const PricingSlider = props => {
  const tablet = useMediaQuery(
    "(min-width: 768px) and (max-width: 1024px) and (orientation: landscape)"
  )
  const laptop = useMediaQuery("(min-width: 1025px)")

  const slidesToShow = laptop === true ? 5 : tablet === true ? 3 : 1

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
  }
  return <Slider {...settings}>{props.children}</Slider>
}

export default PricingSlider
