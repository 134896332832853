import { withStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: "0.8em",
    padding: 20,
    margin: "auto",
  },
}))(Tooltip)

export default LightTooltip
