import React from "react"
import PricingLearnMore from "../buttons/pricing-button-learn-more"
import LinkExternalInternal from "../link/link"

const PopupDialogContent = props => {
  return (
    <div>
      {props.children}
      {props.to && (
        <LinkExternalInternal to={props.to}>
          <PricingLearnMore />
        </LinkExternalInternal>
      )}
    </div>
  )
}

export default PopupDialogContent
