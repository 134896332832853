import React from "react"
import styled from "styled-components"
import ArrowRight from "../../../static/arrow/arrowright.svg"

const PricingLearnMoreContainer = styled.div`
  display: inline-block;
  font-size: 0.8em;
  padding-left: 10px;
  font-style: italic;
  margin: 0;
`

const LearnMore = props => {
  return (
    <PricingLearnMoreContainer style={{ ...props.style }}>
      Read more...{" "}
      <img src={ArrowRight} style={{ padding: 3 }} alt="arrow right" />
    </PricingLearnMoreContainer>
  )
}

export default LearnMore
