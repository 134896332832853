import React, { useState } from "react"
import {
  PriceSwitchText,
  PriceTitleContainer,
  PricingPlanTitle,
  PricingPlanSubTitle,
  SwitchBarContainer,
} from "./pricing"
import Container from "@material-ui/core/Container"
import PricingSlider from "./pricing-slider"
import Switch from "@material-ui/core/Switch"
import { Link } from "gatsby"
import CheckFinancialConditions from "./checkFinancialConditions"
import PricingTableItem from "./pricing-table-item"

const Pricing = props => {
  const [monthly, setMonthly] = useState(false)

  const handleChange = () => event => {
    setMonthly(event.target.checked)
  }

  const [selectedDiscount, setSelectedDiscount] = React.useState(0)
  const [discount, setDiscount] = React.useState(
    props.pricing.paymentterms[selectedDiscount].value
  )

  return (
    <Container
      maxWidth="lg"
      style={{
        marginTop: 100,
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: 30,
      }}
    >
      <PriceTitleContainer>
        <PricingPlanTitle>
          Pricing Plans for {props.pricing.title}
        </PricingPlanTitle>
        <PricingPlanSubTitle>{props.pricing.subtitle}</PricingPlanSubTitle>
        <Link style={{ textAlign: "center" }} to="/contact-sales">
          Smaller or larger system? Custom pricing plan? <br /> Contact us
        </Link>
      </PriceTitleContainer>
      <SwitchBarContainer>
        <PriceSwitchText onClick={() => setMonthly(!monthly)}>
          Monthly
          <Switch checked={monthly} onChange={handleChange("switch")} />
          <div
            style={{
              display: "inline-block",
              color: monthly ? "#f50057" : "inherit",
            }}
          >
            Annual
          </div>
        </PriceSwitchText>
      </SwitchBarContainer>
      <CheckFinancialConditions
        disc={discount}
        discount={props.pricing.paymentterms}
        setSelectedDiscount={setSelectedDiscount}
        setDiscount={setDiscount}
        selectedDiscount={selectedDiscount}
      />
      <PricingSlider>
        {props.pricing.data.map((pricingTable, index) => {
          return (
            <PricingTableItem
              key={index}
              pricingTable={pricingTable}
              monthly={monthly}
              discount={discount}
            />
          )
        })}
      </PricingSlider>
    </Container>
  )
}

export default Pricing
