import React from "react"
import { PaymentsTerms } from "./pricing"
import LightTooltip from "../popup/popup-tooltip"
import PopupDialogContent from "../popup/popup-dialog-content"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import ButtonGroup from "@material-ui/core/ButtonGroup"

const CheckFinancialConditions = props => {
  const PAYEMENT_TERMS_DESCRIPTION =
    "We give our client flexible payment terms starting from 0 days to 90days. Check which subscription model is suitable for you and contact us"

  const [state, setState] = React.useState(false)

  const handleChange = () => event => {
    setState(event.target.checked)
  }

  const handleDiscount = index => {
    props.setSelectedDiscount(index)
    props.setDiscount(props.discount[index].value)
  }

  return (
    <div style={{ textAlign: "center", marginBottom: 45 }}>
      {state === false && (
        <LightTooltip
          title={
            <PopupDialogContent>
              {PAYEMENT_TERMS_DESCRIPTION}
            </PopupDialogContent>
          }
          placement="right"
          interactive="true"
        >
          <PaymentsTerms onClick={() => setState(true)}>
            {" "}
            Need payment terms ?
          </PaymentsTerms>
        </LightTooltip>
      )}
      {state === true && (
        <>
          <div
            style={{ display: "flex", alignItems: "center", justify: "center" }}
          >
            <Checkbox checked={state} onChange={handleChange()} />
            <div>Need payment terms ?</div>
          </div>
          <div>
            <ButtonGroup color="primary" style={{ marginBottom: 15 }}>
              {props.discount.map((item, index) => {
                return (
                  <Button
                    key={index}
                    onClick={() => handleDiscount(index)}
                    color={
                      index === props.selectedDiscount ? "secondary" : "primary"
                    }
                  >
                    {item.name}
                  </Button>
                )
              })}
            </ButtonGroup>
          </div>
        </>
      )}
    </div>
  )
}

export default CheckFinancialConditions
