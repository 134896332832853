import styled from "styled-components"
import React from "react"

const PriceSwitchText = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: rgb(92, 99, 108);
  cursor: pointer;
`

const PricingTable = styled.div`
  border: 1px solid ${props => props.color};
  height: 100%;
  border-radius: 5px;
  padding: 60px 45px;
  @media (max-width: 990px) {
    padding: 30px;
  }
  @media (max-width: 767px) {
    padding: 20px;
  }
  @media (max-width: 575px) {
    padding: 10px;
    min-width: 250px;
  }
  a:not([href]),
  a[href],
  a[data-href] {
    backface-visibility: hidden;
  }
`

const PricingHead = styled.div`
  margin-bottom: 40px;
`

const PricingHeadTitle = styled.h4`
  display: flex;
  justify-content: center;

  @media (min-width: 1200px) {
    min-height: 70px;
  }
`

const PricingHeadSubTitle = styled.p`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 0.8em;
  @media (min-width: 700px) {
    min-height: 3em;
  }
`

const PricingPrice = styled.div`
  margin-bottom: 30px;
  text-align: center;
`

const PricingButtonContainer = styled.div`
  text-align: center;
  margin-bottom: 55px;
  @media (max-width: 767px) {
    margin-bottom: 40px;
  }
`

const PricingButtonFeatured = styled.div`
  font-weight: 600;
  color: #387abe;
  border-radius: 4px;
  width: 100%;
  max-width: 100%;
  padding: 12px;
  border: 1px solid #387abe;
`

const PricingButtonStandard = styled.div`
  font-weight: 600;
  border-radius: 4px;
  bg: #fff;
  background-color: #387abe;
  color: white;
  width: 100%;
  max-width: 100%;
  padding: 12px;
  border: 1px solid #387abe;
`

const PricingButton = props => {
  return (
    <PricingButtonContainer>
      {props.featured ? (
        <PricingButtonFeatured>{props.children}</PricingButtonFeatured>
      ) : (
        <PricingButtonStandard>{props.children}</PricingButtonStandard>
      )}
    </PricingButtonContainer>
  )
}

const PricingList = styled.div``

const ListItem = styled.div`
  display: flex;
  font-size: 0.8em;
  margin-bottom: 19px;
  &:last-child {
    margin-bottom: 0;
  }
  .price_list_icon {
    color: #18d379;
    margin-right: 10px;
  }
`

const PriceTitleContainer = styled.div`
  text-align: center;
  max-width: 600px;
  margin: auto;
`

const PricingPlanTitle = styled.h2`
  text-align: center;
`

const PricingPlanSubTitle = styled.p`
  text-align: center;

  @media (max-width: 700px) {
    display: none;
  }
`

const SwitchBarContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 30px;
`

const PaymentsTerms = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: rgb(92, 99, 108);
  cursor: pointer;
  text-decoration: underline;
  display: inline-block;
  padding: 20px;
`

export {
  PricingHead,
  PricingHeadTitle,
  PricingHeadSubTitle,
  PricingPrice,
  PricingButton,
  PricingList,
  ListItem,
  PriceSwitchText,
  PriceTitleContainer,
  PricingPlanTitle,
  PricingPlanSubTitle,
  SwitchBarContainer,
  PaymentsTerms,
}
export default PricingTable
